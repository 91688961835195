"use client";

import { motion, useAnimation } from "framer-motion";
import { ReactNode, useEffect } from "react";
import { useInView } from "react-intersection-observer";

export default function FadeInUp({
  children,
  delay,
  duration,
}: {
  children: ReactNode;
  delay?: number;
  duration?: number;
}) {
  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.03 });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: duration ?? 0.6, delay: delay ?? 0 },
    },
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={variants}
    >
      {children}
    </motion.div>
  );
}
